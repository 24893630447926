import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DocumentDesc } from '@stimcar/libs-base';
import type {
  ActionContext,
  NoArgActionCallback,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { CheckFormConsistencyAction, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { EMPTY_DOCUMENT_DESC, nonDeleted } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  FormField,
  Input,
  ModalCardDialog,
  SelectFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { DocumentDescFormData, EditDocumentDescModalState } from '../typings/store.js';
import { EDIT_DOCUMENT_DESC_MODAL_EMPTY_STATE } from '../typings/store.js';

function convertDocumentDescFormDataToDocumentDesc(formData: DocumentDescFormData): DocumentDesc {
  return {
    ...EMPTY_DOCUMENT_DESC,
    id: formData.id,
    label: isTruthyAndNotEmpty(formData.label) ? formData.label : '',
    targetFolder: formData.targetFolder,
    standId: formData.standId,
  };
}

function saveDocumentDescAction({
  getState,
  actionDispatch,
}: ActionContext<Store, EditDocumentDescModalState>): void {
  const { initialDocumentDesc, formData, documentDescs } = getState();
  let newDocumentDescs = [...documentDescs];
  if (initialDocumentDesc) {
    newDocumentDescs = newDocumentDescs.map((doc) => {
      if (doc.id === formData.id) {
        return convertDocumentDescFormDataToDocumentDesc(formData);
      }
      return doc;
    });
  } else {
    const newDocument = convertDocumentDescFormDataToDocumentDesc(formData);
    const existingDeletedDocument = newDocumentDescs.find(
      (doc) => !nonDeleted(doc) && doc.label === newDocument.label
    );
    if (isTruthy(existingDeletedDocument)) {
      newDocumentDescs = newDocumentDescs.map((doc) => {
        if (doc.id === existingDeletedDocument.id) {
          return newDocument;
        }
        return doc;
      });
    } else {
      newDocumentDescs.push(newDocument);
    }
  }

  actionDispatch.setValue({
    ...EDIT_DOCUMENT_DESC_MODAL_EMPTY_STATE,
    documentDescs: newDocumentDescs,
  });
}

const mandatoryFields: (keyof DocumentDescFormData)[] = ['label', 'targetFolder'];

const checkFormConsistencyAction: CheckFormConsistencyAction<Store, EditDocumentDescModalState> = ({
  formState,
  t,
}): string | undefined => {
  const { initialDocumentDesc, documentDescs, formData } = formState;
  // First we check if the label is not already used
  const labelAlreadyUsed = documentDescs
    .filter((doc) => nonDeleted(doc))
    .some(
      (doc) =>
        doc.label === formData.label &&
        (initialDocumentDesc === undefined || initialDocumentDesc.id !== doc.id)
    );
  if (labelAlreadyUsed) {
    return t('documentDescModal.form.warnings.labelAlreadyUsed');
  }

  return undefined;
};

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: 5,
  labelFlexGrow: 2,
};

interface EditDocumentDescModalProps {
  readonly standIds: readonly string[];
  readonly runValidationGlobalCallback: NoArgActionCallback<Store>;
  readonly $: StoreStateSelector<Store, EditDocumentDescModalState>;
}

export function EditDocumentDescModal({
  $,
  standIds,
  runValidationGlobalCallback,
}: EditDocumentDescModalProps): JSX.Element {
  const [t] = useTranslation('packageDealDescs');
  const formWarning = useGetState($.$formWarning);
  const initialDocumentDesc = useGetState($.$initialDocumentDesc);
  const isCreateMode = useMemo(() => !isTruthy(initialDocumentDesc), [initialDocumentDesc]);
  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(saveDocumentDescAction);
      await actionDispatch.execCallback(runValidationGlobalCallback);
    },
    [runValidationGlobalCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    EditDocumentDescModalState
  >({
    $,
    mandatoryFields,
    submitValidDataAction,
    checkFormConsistencyAction,
    t,
  });

  return (
    <ModalCardDialog
      title={isCreateMode ? t('documentDescModal.titleNew') : t('documentDescModal.titleUpdate')}
      $active={$.$active}
      okLabel={
        isCreateMode
          ? t('documentDescModal.form.newButton')
          : t('documentDescModal.form.updateButton')
      }
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <FormField label={t('documentDescModal.form.label')} horizontal>
        <Input
          $={$formDataWithChangeTrigger.$label}
          placeholder={t('documentDescModal.form.label')}
        />
      </FormField>
      <FormField label={t('documentDescModal.form.targetFolder')} horizontal>
        <Input
          $={$formDataWithChangeTrigger.$targetFolder}
          placeholder={t('documentDescModal.form.targetFolder')}
        />
      </FormField>
      <SelectFormField
        label={t('operationDescModal.form.stand')}
        entries={standIds}
        $={$formDataWithChangeTrigger.$standId}
        isEmptyValueAllowed
        isFullWidth
        horizontal={HORIZONTAL_PROPS}
      />
    </ModalCardDialog>
  );
}
