import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Attachment, AttachmentFolder, StorageCategories } from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  State,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import {
  ATTACHMENT_TEMPORARY_FOLDER_PREFIX,
  enumerate,
  forEachRecordValues,
  urlHelpers,
} from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useFormFieldWarning, useGetState } from '@stimcar/libs-uikernel';
import type { BaseStoreDefWithHttpClient } from '../../../app/index.js';
import type {
  CheckFormConsistencyAction,
  CheckFormFieldContentActions,
} from '../../hooks/index.js';
import { Input, ModalCardDialog } from '../../bulma/index.js';
import { useFormWithValidation } from '../../hooks/index.js';
import type { LoadAttachmentsActionCallback } from './AttachmentsGallery.js';
import type {
  ComputeAttachmentUrlCallback,
  ImportAttachmentsActionCallback,
} from './typings/attachment.js';
import type {
  ImageModalState,
  PdfCreationAndUploadModalState,
  PdfCreationFormData,
} from './typings/store.js';
import { AttachmentThumbnail } from './AttachmentThumbnail.js';
import { loadFile } from './ImportButton.js';
import { PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE } from './typings/store.js';

export const PDF_EXTENSION = '.pdf';

export type ConvertToPdfHttpRequestAction<SD extends BaseStoreDefWithHttpClient> = (
  ctx: ActionContext<SD, State>,
  category: StorageCategories,
  objectId: string,
  sourceFolderId: string,
  targetFolderId: string,
  attachmentNames: string[],
  filename: string
) => Promise<void>;

export type RemovePdfPageHttpRequestAction<SD extends BaseStoreDefWithHttpClient> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ctx: ActionContext<SD, any>,
  category: StorageCategories,
  objectId: string,
  folder: string,
  filename: string
) => Promise<void>;

function getFolderName(folder: AttachmentFolder): string {
  return isTruthyAndNotEmpty(folder.label) ? folder.label : folder.id;
}

function computePdfDefaultName(folder: AttachmentFolder): string {
  const date = new Date();
  const dateText = `${date.getFullYear()}${date.toLocaleString(undefined, {
    month: '2-digit',
  })}${date.getDate()}`;
  const timeText = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
  return `${getFolderName(folder)}_${dateText}-${timeText}${PDF_EXTENSION}`;
}

export function openPdfCreationAndUploadModal<SD extends BaseStoreDefWithHttpClient>(
  { actionDispatch }: ActionContext<SD, PdfCreationAndUploadModalState>,
  category: StorageCategories,
  folder: AttachmentFolder,
  objectId: string
): void {
  actionDispatch.reduce(() => {
    return {
      ...PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE,
      isActive: true,
      category,
      folder,
      objectId,
      formData: {
        ...PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE.formData,
        pdfName: computePdfDefaultName(folder),
      },
    };
  });
}

const checkFieldContentActions: CheckFormFieldContentActions<
  BaseStoreDefWithHttpClient,
  PdfCreationAndUploadModalState
> = {
  pdfName: ({ value, t }): string | undefined => {
    const restrictedChar = urlHelpers.getURLParametersRestrictedCharacters(value);
    if (restrictedChar.length > 0) {
      return restrictedChar.length === 1
        ? t(`attachments.pdfCreationAndUploadModal.warnings.restrictedCharInFilename`, {
            restrictedChar: restrictedChar[0],
          })
        : t(`attachments.pdfCreationAndUploadModal.warnings.restrictedCharsInFilename`, {
            restrictedChars: enumerate(restrictedChar, ', ', { prefix: '"', suffix: '"' }),
          });
    }
    return undefined;
  },
};

const checkFormConsistencyAction: CheckFormConsistencyAction<
  BaseStoreDefWithHttpClient,
  PdfCreationAndUploadModalState
> = ({ formState, t }): string | undefined => {
  const { attachments } = formState;
  if (attachments.length === 0) {
    return t('attachments.pdfCreationAndUploadModal.warnings.shouldReferenceAtLeastOneAttachment');
  }
  return undefined;
};

const mandatoryFields: (keyof PdfCreationFormData)[] = ['pdfName'];

interface Props<SD extends BaseStoreDefWithHttpClient> {
  readonly $: StoreStateSelector<SD, PdfCreationAndUploadModalState>;
  readonly $imageModal: StoreStateSelector<SD, ImageModalState>;
  readonly importAttachmentsActionCallback: ImportAttachmentsActionCallback<SD>;
  readonly removePdfPageHttpRequestAction: RemovePdfPageHttpRequestAction<SD>;
  readonly computeAttachmentUrl: ComputeAttachmentUrlCallback;
  readonly convertToPdfHttpRequestAction: ConvertToPdfHttpRequestAction<SD>;
  readonly loadAttachmentsActionCallback: LoadAttachmentsActionCallback<SD>;
  readonly isOnline: boolean;
  readonly clientSpecificFolderId: string;
  readonly postUploadAdditionalGlobalActionCallback?: ActionCallbackFromFunction<
    SD,
    (folderToRefresh: readonly string[], fileName: string) => Promise<void>
  >;
  readonly selectMode?: 'multiple' | 'one';
  readonly onClickOnThumbnailBehavior?: 'openImageModal' | 'select';
}

export function PdfCreationAndUploadModal<SD extends BaseStoreDefWithHttpClient>({
  $,
  $imageModal,
  importAttachmentsActionCallback,
  removePdfPageHttpRequestAction,
  loadAttachmentsActionCallback,
  isOnline,
  convertToPdfHttpRequestAction,
  clientSpecificFolderId,
  computeAttachmentUrl,
  postUploadAdditionalGlobalActionCallback,
  selectMode,
  onClickOnThumbnailBehavior,
}: Props<SD>): JSX.Element {
  const isActive = useGetState($.$isActive);
  return (
    <>
      {isActive && (
        <InternalPdfCreationAndUploadModal
          $={$}
          clientSpecificFolderId={clientSpecificFolderId}
          $imageModal={$imageModal}
          importAttachmentsActionCallback={importAttachmentsActionCallback}
          loadAttachmentsActionCallback={loadAttachmentsActionCallback}
          removePdfPageHttpRequestAction={removePdfPageHttpRequestAction}
          computeAttachmentUrl={computeAttachmentUrl}
          convertToPdfHttpRequestAction={convertToPdfHttpRequestAction}
          postUploadAdditionalGlobalActionCallback={postUploadAdditionalGlobalActionCallback}
          isOnline={isOnline}
          selectMode={selectMode}
          onClickOnThumbnailBehavior={onClickOnThumbnailBehavior}
        />
      )}
    </>
  );
}

function InternalPdfCreationAndUploadModal<SD extends BaseStoreDefWithHttpClient>({
  $,
  $imageModal,
  importAttachmentsActionCallback,
  clientSpecificFolderId,
  loadAttachmentsActionCallback,
  removePdfPageHttpRequestAction,
  convertToPdfHttpRequestAction,
  isOnline,
  computeAttachmentUrl,
  postUploadAdditionalGlobalActionCallback,
  selectMode,
  onClickOnThumbnailBehavior,
}: Props<SD>): JSX.Element {
  const [t] = useTranslation('custom');

  const attachments = useGetState($.$attachments);
  const formWarning = useGetState($.$formWarning);

  const category = useGetState($.$category);
  const objectId = useGetState($.$objectId);
  const folder = useGetState($.$folder);
  const loadingStatus = useGetState($.$loadingStatus);

  const personalTemporaryFolder = ATTACHMENT_TEMPORARY_FOLDER_PREFIX + clientSpecificFolderId;

  const asyncEffect = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      if (isOnline) {
        const { objectId, category } = getState();
        actionDispatch.setProperty('loadingStatus', t('attachments.gallery.loading'));
        await actionDispatch.execCallback(loadAttachmentsActionCallback, category, objectId, [
          personalTemporaryFolder,
        ]);
        actionDispatch.setProperty('loadingStatus', undefined);
      }
    },
    [isOnline, loadAttachmentsActionCallback, personalTemporaryFolder, t],
    $
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useEffect((): any => {
    if (isTruthyAndNotEmpty(objectId)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncEffect();
    }
  }, [asyncEffect, objectId]);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch, globalActionDispatch, getState }): Promise<void> => {
      const { attachments, formData } = getState();
      const attachmentsNames = attachments.map((a): string => a.name);
      let { pdfName } = formData;
      if (!pdfName.endsWith(PDF_EXTENSION)) {
        pdfName += PDF_EXTENSION;
      }

      await actionDispatch.exec(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        convertToPdfHttpRequestAction as any,
        category,
        objectId,
        personalTemporaryFolder,
        nonnull(folder).id,
        attachmentsNames,
        pdfName
      );

      actionDispatch.reduce((initial) => {
        return { ...initial, ...PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE };
      });
      if (postUploadAdditionalGlobalActionCallback) {
        await globalActionDispatch.execCallback(
          postUploadAdditionalGlobalActionCallback,
          [nonnull(folder).id],
          pdfName
        );
      }
    },
    [
      category,
      convertToPdfHttpRequestAction,
      folder,
      objectId,
      personalTemporaryFolder,
      postUploadAdditionalGlobalActionCallback,
    ],
    $
  );

  const [onFormSubmit, genericOnFormChange, $formDataWithChangeTrigger] = useFormWithValidation<
    SD,
    PdfCreationAndUploadModalState
  >({
    $,
    mandatoryFields,
    checkFieldContentActions,
    checkFormConsistencyAction,
    submitValidDataAction,
    t,
  });

  const onImportAttachmentCallback = useActionCallback(
    async ({ actionDispatch }, event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const files = [...nonnull(nonnull(event.target).files)];
      await Promise.all(files.map(async (f): Promise<void> => loadFile(f)));

      const addedAttachments: Attachment[] = [];
      await actionDispatch.execCallback(
        importAttachmentsActionCallback,
        category,
        objectId,
        personalTemporaryFolder,
        files,
        (fileNamesMap: Record<string, string>) => {
          forEachRecordValues(fileNamesMap, (v) => {
            addedAttachments.push({
              id: v,
              name: v,
              folder: personalTemporaryFolder,
            });
          });
        }
      );

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          attachments: [...initial.attachments, ...addedAttachments],
        };
      });
      await actionDispatch.execCallback(genericOnFormChange);
    },
    [
      category,
      genericOnFormChange,
      importAttachmentsActionCallback,
      objectId,
      personalTemporaryFolder,
    ],
    $
  );

  const removeAttachmentHandler = useActionCallback(
    async (
      { actionDispatch },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      theFolder: string,
      filename: string,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id: string
    ): Promise<void> => {
      await actionDispatch.exec(
        removePdfPageHttpRequestAction,
        category,
        objectId,
        personalTemporaryFolder,
        filename
      );
      actionDispatch.reduce((initial) => {
        const newAttachments = initial.attachments.filter((a) => a.name !== filename);
        return {
          ...initial,
          attachments: newAttachments,
        };
      });
    },
    [category, objectId, personalTemporaryFolder, removePdfPageHttpRequestAction],
    $
  );

  const cancelHandler = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      if (isOnline) {
        const { attachments } = getState();
        await Promise.all(
          attachments.map(async (a): Promise<void> => {
            await actionDispatch.exec(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              removePdfPageHttpRequestAction as any,
              category,
              objectId,
              personalTemporaryFolder,
              a.name
            );
          })
        );
      }

      actionDispatch.reduce((initial) => {
        return { ...initial, ...PDF_CREATION_AND_UPLOAD_MODAL_EMPTY_STATE };
      });
    },
    [category, isOnline, objectId, personalTemporaryFolder, removePdfPageHttpRequestAction],
    $
  );

  const nameInputWarnings = useFormFieldWarning($formDataWithChangeTrigger.$pdfName);

  return (
    <ModalCardDialog
      $active={$.$isActive}
      title={t('attachments.pdfCreationAndUploadModal.title', {
        folder: getFolderName(nonnull(folder)),
      })}
      okLabel={t('attachments.pdfCreationAndUploadModal.okButton')}
      onOkClicked={onFormSubmit}
      onCancelClicked={cancelHandler}
      warning={
        !isOnline ? t('attachments.pdfCreationAndUploadModal.warnings.noNetwork') : formWarning
      }
      noContentTag
    >
      {loadingStatus ? (
        <div className="content">
          <p>{loadingStatus}</p>
        </div>
      ) : (
        <>
          <div className="columns is-mobile">
            <div className="column">
              <Input $={$formDataWithChangeTrigger.$pdfName} />
              {nameInputWarnings && <p className="help is-primary">{nameInputWarnings}</p>}
            </div>
          </div>
          <div className="columns is-mobile is-multiline is-vcentered">
            {attachments.map(
              (a, i): JSX.Element => (
                <div className="column is-narrow" key={`${a.folder}/${a.id}`}>
                  <AttachmentThumbnail
                    category={category}
                    objectId={objectId}
                    size={128}
                    computeAttachmentUrl={computeAttachmentUrl}
                    caption={a.name}
                    attachments={attachments}
                    $imageModal={$imageModal}
                    attachmentIndex={i}
                    onRemoveCallback={removeAttachmentHandler}
                    selectMode={selectMode}
                    onClickBehavior={onClickOnThumbnailBehavior}
                  />
                </div>
              )
            )}
            <div className="column is-narrow">
              <div className="file is-small is-boxed">
                <label className="file-label">
                  <input
                    id="fileUpload"
                    className="file-input"
                    type="file"
                    accept="image/*"
                    disabled={!isOnline}
                    name="resume"
                    value=""
                    capture
                    onChange={onImportAttachmentCallback}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload" />
                    </span>
                    <span className="file-label">
                      {t('attachments.pdfCreationAndUploadModal.addPageButtonLabel')}
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalCardDialog>
  );
}
