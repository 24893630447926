import type {
  MergedCarViewCategory,
  OperationDocumentsInfo,
  OperationRequestMessage,
  SubcontractorKanban,
  SubcontractorOperation,
} from '@stimcar/libs-base';
import type {
  AttachmentGalleryState,
  AttachmentsTabState,
  ExpandedKanbanStatuses,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_SUBCONTRACTOR_OPERATION } from '@stimcar/libs-base';
import {
  ATTACHMENTS_TAB_EMPTY_STATE,
  EMPTY_ATTACHMENT_GALLERY_STATE,
} from '@stimcar/libs-uitoolkit';
import type { SubcontractorUIOperation } from '../../lib/subcontractorCardUtils.js';
import { EMPTY_OPERATION_DOCUMENT_INFOS } from '../../../app/utils/operationCompletion/typings/store.js';

export type SubcontractorOperationMessageModal = {
  readonly active: boolean;
  readonly messageContent: string;
  readonly uiOperation: SubcontractorUIOperation | undefined;
  readonly initialMessage: OperationRequestMessage | undefined;
};

export const SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE: SubcontractorOperationMessageModal =
  {
    active: false,
    messageContent: '',
    uiOperation: undefined,
    initialMessage: undefined,
  };

export type OtherOperatationTabState = {
  readonly expandedCategories: Record<MergedCarViewCategory, boolean>;
};

export type OperationToDoState = {
  readonly isAttachmentsExpanded: boolean;
};

export type OperationsToDoTabState = {
  readonly operationsState: Record<string, OperationToDoState>;
};

export type SubcontractorUploadDocumentForCompletionModalState = {
  readonly active: boolean;
  readonly kanbanId: string;
  readonly warning?: string;
  readonly operation: SubcontractorOperation;
  readonly documentsInfo: OperationDocumentsInfo;
  readonly attachmentGallery: AttachmentsTabState;
};

export const EMPTY_SUBCONTRACTOR_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE: SubcontractorUploadDocumentForCompletionModalState =
  {
    kanbanId: '',
    active: false,
    operation: EMPTY_SUBCONTRACTOR_OPERATION,
    attachmentGallery: ATTACHMENTS_TAB_EMPTY_STATE,
    documentsInfo: EMPTY_OPERATION_DOCUMENT_INFOS,
  };

export type SubcontractorOperateState = {
  readonly kanban: SubcontractorKanban | undefined;
  readonly tab: 'operationsToDo' | 'otherOperations' | 'messages' | 'folders';
  readonly messageModal: SubcontractorOperationMessageModal;
  readonly attachmentsState: AttachmentGalleryState;
  readonly otherOperationsTabState: OtherOperatationTabState;
  readonly operationsToDoTabState: OperationsToDoTabState;
  readonly expandedKanbanStatuses: ExpandedKanbanStatuses;
  readonly subcontractorUploadDocumentForCompletionModal: SubcontractorUploadDocumentForCompletionModalState;
};

export const SUBCONTRACTOR_OPERATE_EMPTY_STATE: SubcontractorOperateState = {
  kanban: undefined,
  tab: 'operationsToDo',
  messageModal: SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
  attachmentsState: EMPTY_ATTACHMENT_GALLERY_STATE,
  otherOperationsTabState: {
    expandedCategories: {
      BODY: false,
      INTE: false,
      MECA: false,
      MISC: false,
    },
  },
  operationsToDoTabState: {
    operationsState: {},
  },
  expandedKanbanStatuses: {},
  subcontractorUploadDocumentForCompletionModal:
    EMPTY_SUBCONTRACTOR_UPLOAD_DOCUMENT_FOR_COMPLETION_MODAL_STATE,
};
