import type { Entity, KanbanInfos } from '@stimcar/libs-kernel';
import { EMPTY_ENTITY } from '../globalConstants.js';
import type { CarViewCategory, KanbanPriorityLevel } from './configuration.js';
import type { AttachmentFolder, WithAttachments } from './general.js';
import type {
  KanbanLogisticInfos,
  KanbanMessage,
  OperationDocumentsInfo,
  OperationType,
} from './kanban.js';

export type SubcontractorSparePart = {
  readonly id: string;
  readonly label: string;
  readonly quantity: number;
  readonly stand: string;
};

export const EMPTY_SUBCONTRACTOR_OPERATION: SubcontractorOperation = {
  ...EMPTY_ENTITY,
  label: '',
  workload: 0,
  stand: '',
  completionDate: null,
};

export type SubcontractorOperation = {
  readonly id: string;
  readonly workload: number;
  readonly label: string;
  readonly stand: string;
  readonly completionDate: number | null;
  readonly type?: OperationType | undefined;
  readonly documentsInfo?: OperationDocumentsInfo | undefined;
};

export type SubcontractorCarElement = {
  readonly label: string;
  readonly category: CarViewCategory;
  readonly shapes: readonly string[];
};

export type SubcontractorPackageDeal = WithAttachments & {
  readonly id: string;
  readonly label: string;
  readonly carElement: SubcontractorCarElement | null;
  readonly comment: string;
  readonly operations: readonly SubcontractorOperation[];
  readonly spareParts: readonly SubcontractorSparePart[];
  readonly tags: readonly string[];
};

export type SubcontractorUnassignedPackageDealStatus = 'notStarted' | 'doing' | 'done';

/**
 * Used for subcontractors that need to see other package deals than those they have to do.
 */
export type SubcontractorUnassignedPackageDeal = Entity & {
  readonly label: string;
  readonly carElement: Omit<SubcontractorCarElement, 'shapes'> | null;
  readonly status: SubcontractorUnassignedPackageDealStatus;
};

export type SubcontractorKanban = {
  readonly id: string;
  readonly priority: KanbanPriorityLevel;
  readonly iconId: string | null;
  readonly infos: KanbanInfos;
  readonly logisticInfos: KanbanLogisticInfos;
  readonly contractCode: string;
  readonly packageDealsToHandle: readonly SubcontractorPackageDeal[];
  readonly unassignedPackageDeals?: readonly SubcontractorUnassignedPackageDeal[];
  readonly messages: readonly KanbanMessage[];
  readonly folders: readonly AttachmentFolder[];
};

/**
 * Used to store the kanbans on the post and it must have a property `waitingLine` used for kanbans that are in the waitinLine of the category
 */
export type AllWorkshopSubcontractorKanbansOnCategory = Record<
  string,
  SubcontractorKanban | null
> & {
  // FIXME this array should be readonly, but the code in the server must be refactored in order no to use the push method
  // (and instead use a reducer strategy for example)
  readonly waitingLine: SubcontractorKanban[];
};

/**
 * Used to group kanbans by categories in an implantation. It must have a property `sortedAvailableCategoryIds` used to get the correct ordering of categories in this implantation
 */
export type AllWorkshopSubcontractorKanbansOnImplantation = Record<
  string,
  AllWorkshopSubcontractorKanbansOnCategory
> & {
  readonly sortedAvailableCategoryIds: readonly string[];
};

/**
 * Used to group kanban by implantation.
 */
export type AllWorkshopSubcontractorKanbans = Record<
  string,
  AllWorkshopSubcontractorKanbansOnImplantation
>;

export type SubcontractorKanbansPerStand = Record<
  string,
  readonly SubcontractorKanban[] | AllWorkshopSubcontractorKanbans
>;
/**
 * The data structure used to send all available kanbans for a subcontractor
 */
export type GetAllSubcontractorKanbansResult = {
  readonly standOrder: readonly string[];
  readonly kanbansPerStandMap: SubcontractorKanbansPerStand;
};
