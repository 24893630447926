import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, WorkshopOperation } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { workshopHelpers } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { SubcontractableInfo } from '../../../../app/utils/operationCompletion/SelectSubcontractorFirmModalDialog.js';
import type { WorkshopPostOperationsModalState } from '../typings/store.js';
import { AdditionalStepsForOperationCompletionModal } from '../../../../app/utils/operationCompletion/AdditionalStepsForOperationCompletionModal.js';
import { useComputeAttachmentUrl } from '../../../../app/utils/useComputeAttachmentUrl.js';
import { getImplantation } from '../../../../app/utils/useGetCurrentWorkshopOperatorImplantation.js';
import { useGetCurrentWorkshopPostDisplayedLabels } from '../../../../app/utils/useGetCurrentWorkshopPostDisplayedLabels.js';
import { WorkshopPostOperations } from '../post/WorkshopPostOperations.js';
import { EMPTY_WORKSHOP_OPERATIONS_MODAL_STATE } from '../typings/store.js';
import { toggleCompletedOperationStatusAction } from './workshopImplantationUtils.js';

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, WorkshopPostOperationsModalState>;
  readonly kanbans: readonly Kanban[];
}

export function WorkshopPostOperationsModal({ $gs, $, kanbans }: Props): JSX.Element {
  const [t] = useTranslation('workshop');

  const computeAttachmentUrlCallback = useComputeAttachmentUrl($gs);
  const kanbanId = useGetState($.$kanbanId);
  const qualifiedPostId = useGetState($.$qualifiedPostId);
  const standId = useGetState($.$standId);
  const allowOperationsToggling = useGetState($.$allowOperationsToggling);
  const configuration = useGetState($gs.$siteConfiguration);
  const implantation = getImplantation(qualifiedPostId, configuration, standId);

  const { postLabel } = useGetCurrentWorkshopPostDisplayedLabels($gs);

  const closeModalCallback = useActionCallback(
    ({ actionDispatch }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionDispatch.reduce((initial) => {
        return EMPTY_WORKSHOP_OPERATIONS_MODAL_STATE;
      });
    },
    [],
    $
  );

  const toggleFinishOperationStatusCallback = useActionCallback(
    async (
      { actionDispatch },
      operationId: string,
      subcontractorInfo?: SubcontractableInfo,
      selectedFiles?: readonly string[]
    ) => {
      await actionDispatch.exec(
        toggleCompletedOperationStatusAction,
        kanbanId!,
        operationId,
        subcontractorInfo,
        selectedFiles
      );
    },
    [kanbanId],
    $
  );

  const theKanban = useMemo(() => kanbans.find((k) => k.id === kanbanId), [kanbans, kanbanId]);

  const workshopOperations = useMemo((): Record<string, readonly WorkshopOperation[]> => {
    return workshopHelpers.getWorkshopOperationsGroupedByLabel(
      theKanban,
      qualifiedPostId,
      standId,
      implantation
    );
  }, [theKanban, qualifiedPostId, standId, implantation]);

  return (
    <ModalCardDialog
      $active={$.$active}
      noContentTag
      size="fullscreen"
      title={
        isTruthy(theKanban)
          ? `${theKanban.infos.license}-${theKanban.infos.model}`
          : t('post.noKanban')
      }
      onCancelClicked={closeModalCallback}
    >
      <WorkshopPostOperations
        postLabel={postLabel}
        operations={workshopOperations}
        $expandedOperationIds={$.$expendedOperationIds}
        $gs={$gs}
        kanbanId={kanbanId ?? ''}
        computeAttachmentUrlCallback={computeAttachmentUrlCallback}
        toggleOperationFinished={
          allowOperationsToggling ? toggleFinishOperationStatusCallback : undefined
        }
      />
      <AdditionalStepsForOperationCompletionModal
        $={$}
        $gs={$gs}
        toggleOperationActionCallback={toggleFinishOperationStatusCallback}
      />
    </ModalCardDialog>
  );
}
