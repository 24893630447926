import type { RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type {
  CarViewCategory,
  Kanban,
  Operation,
  PackageDeal,
  PreAllocationEntity,
  PreAllocationRequestResult,
  WorkshopPostCategory,
  WorkshopStandImplantation,
} from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import {
  carElementHelpers,
  CoreBackendRoutes,
  globalHelpers,
  kanbanHelpers,
  nonDeleted,
  OPERATION_ATTRIBUTES,
  packageDealHelpers,
  sortingHelpers,
  transverseHelpers,
  workflowHelpers,
  workflowProgressHelpers,
  WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID,
  WORKSHOP_POST_ID_SEPARATOR,
} from '@stimcar/libs-base';
import { applyPayload, isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { Store } from '../../../../app/state/typings/store.js';
import type { SubcontractableInfo } from '../../../../app/utils/operationCompletion/SelectSubcontractorFirmModalDialog.js';
import type { AdditionalStepsForOperationCompletionModalState } from '../../../../app/utils/operationCompletion/typings/store.js';
import type {
  KanbanItem,
  OperationsRedispatchModalState,
  SelectKanbanWizardState,
  WorkshopImplantationViewState,
} from '../typings/store.js';
import { toggleOperationAction } from '../../../../app/utils/operationCompletion/AdditionalStepsForOperationCompletionModal.js';
import { areOriginCategoryBeforeDestinationCategoryInTheSameTube } from '../../../../app/utils/useGetFurtherCategories.js';
import {
  EMPTY_SELECT_KANBAN_WIZARD_STATE,
  OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE,
} from '../typings/store.js';

/**
 * Compute the postId of the anomaly post.
 * Format is ${implantationId}${WORKSHOP_POST_ID_SEPARATOR}${WORKSHOP_DASHBOARD_ANOMALY_POST_ID}${WORKSHOP_POST_ID_SEPARATOR}
 */
export const computeAnomalyPostQualifiedCategoryId = (implantationId: string): string =>
  implantationId + WORKSHOP_POST_ID_SEPARATOR + WORKSHOP_IMPLANTATION_ANOMALY_POST_CATEGORY_ID;

export function convertFromKanbanSelectionWizardItem(kanban: KanbanItem): Kanban {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mecaWorkload, exteWorkload, inteWorkload, miscWorkload, totalWorkload, ...rest } = kanban;
  return workflowProgressHelpers.convertFromWithProgressType(rest);
}

export function getIdsOfKanbansInAnomaly(
  implantationId: string,
  kanbans: readonly Kanban[]
): string[] {
  const kanbansInAnomaly: Kanban[] = [];

  // We don't care about the precise index of the post, we just want to get all anomaly posts, not one precisely
  // i.e we want to match with myImplantation:anomaly
  const stringToSearch = computeAnomalyPostQualifiedCategoryId(implantationId);
  kanbans.forEach((k) => {
    for (const handing of k.handlings) {
      if (!isTruthy(handing.endDate) && handing.postId.startsWith(stringToSearch)) {
        kanbansInAnomaly.push(k);
        break;
      }
    }
  });

  return kanbansInAnomaly.map((k) => k.id);
}

export const createSortWorkshopPostKanbansByRecency = (
  standId: string,
  qualifiedPostId: string
): ((k1: Kanban, k2: Kanban) => number) => {
  return function sort(k1, k2): number {
    const { handlings: handlings1 } = k1;
    const standHandlings1 = handlings1.filter(
      (h) => standId === h.standId && qualifiedPostId === h.postId && !h.endDate
    );
    const startDate1 = standHandlings1.map((h): number => h.startDate).sort()[0];

    const { handlings: handlings2 } = k2;
    const standHandlings2 = handlings2.filter(
      (h) => standId === h.standId && qualifiedPostId === h.postId && !h.endDate
    );
    const startDate2 = standHandlings2.map((h): number => h.startDate).sort()[0];

    return sortingHelpers.compareNumbers(startDate1, startDate2, 'DOWN');
  };
};

function shouldBeReassigned(
  standId: string,
  furtherCategoryIds: string[],
  o: Operation,
  implantation: WorkshopStandImplantation,
  destinationCategoryId: string
): boolean {
  if (o.standId !== standId) {
    return false;
  }
  const attribute = o.attributes[OPERATION_ATTRIBUTES.WORKSHOP_POST];
  const { implantationId: opCurrentImplantationId, categoryId: opCurrentCategoryId } =
    transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(String(attribute));
  if (opCurrentImplantationId !== implantation.id) {
    return true;
  }
  if (
    isTruthyAndNotEmpty(opCurrentCategoryId) &&
    areOriginCategoryBeforeDestinationCategoryInTheSameTube(
      implantation,
      opCurrentCategoryId,
      destinationCategoryId
    )
  ) {
    return false;
  }
  return (
    packageDealHelpers.isOperationUnfinished(o) &&
    (opCurrentCategoryId === undefined || !furtherCategoryIds.includes(opCurrentCategoryId))
  );
}

export async function assignAllUnfinishedAndUnassignedTubeOperationsToCategory(
  httpClient: RepositoryHTTPClient,
  isOnline: boolean,
  kanban: Kanban,
  standId: string,
  implantation: WorkshopStandImplantation,
  destinationCategoryId: string
): Promise<Kanban> {
  const preAllocation: PreAllocationEntity[] = [];
  const furtherCategoryIds = transverseHelpers
    .getFurtherWorkshopCategories(implantation, destinationCategoryId, true)
    .map((c) => c.id);
  if (isOnline) {
    const result = await httpClient.httpGetAsJson<PreAllocationRequestResult>(
      CoreBackendRoutes.GET_OPERATIONS_PRE_ALLOCATION(
        standId,
        implantation.id,
        destinationCategoryId,
        kanban.id
      )
    );
    preAllocation.push(...result.preAllocation);
  }

  const categoryQualifiedId = globalHelpers.computeQualifiedWorkshopPostId(
    implantation.id,
    destinationCategoryId
  );
  const packageDeals = packageDealHelpers
    .getAvailablePackageDeals(kanban.packageDeals)
    .map((pd): PackageDeal => {
      return {
        ...pd,
        operations: pd.operations.filter(nonDeleted).map((o): Operation => {
          if (
            shouldBeReassigned(standId, furtherCategoryIds, o, implantation, destinationCategoryId)
          ) {
            const preAllocationValue = preAllocation.find(
              (pv) => pv.packageDealCode === pd.code && pv.operationLabel === o.label
            );
            let selectedQualifiedCategory = categoryQualifiedId;
            if (
              isTruthy(preAllocationValue) &&
              furtherCategoryIds.includes(preAllocationValue.categoryId)
            ) {
              selectedQualifiedCategory = globalHelpers.computeQualifiedWorkshopPostId(
                implantation.id,
                preAllocationValue.categoryId
              );
            }

            return {
              ...o,
              attributes: {
                ...o.attributes,
                [OPERATION_ATTRIBUTES.WORKSHOP_POST]: selectedQualifiedCategory,
              },
            };
          }
          return o;
        }),
      };
    });
  return applyPayload(kanban, { packageDeals });
}

export async function initializeRedispatchOperationsAfterKanbanMovingModal(
  {
    actionDispatch,
    getState,
    getGlobalState,
    httpClient,
  }: ActionContext<Store, WorkshopImplantationViewState>,
  standId: string,
  implantation: WorkshopStandImplantation,
  destinationPostCategory: string,
  destinationPostLabel: string | undefined,
  movedKanbanId: string,
  users: readonly string[],
  originPostCategory: string
): Promise<void> {
  const { kanbans } = getState();
  const { isOnline } = getGlobalState().session;
  const movedKanban = nonnull(kanbans.find((k) => k.id === movedKanbanId));
  const kanbanWithOperationAssigned =
    await assignAllUnfinishedAndUnassignedTubeOperationsToCategory(
      httpClient,
      isOnline,
      nonnull(movedKanban),
      standId,
      implantation,
      destinationPostCategory
    );

  actionDispatch.scopeProperty('operationsRedispatchModal').reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial): OperationsRedispatchModalState => {
      return {
        ...OPERATIONS_REDISPATCH_MODAL_EMPTY_STATE,
        isActive: true,
        redispatchMotive: 'kanbanMoving',
        standId,
        implantation,
        users,
        destinationCategory: destinationPostCategory,
        kanbanWithOperationsDispatched: kanbanWithOperationAssigned,
        destinationPostLabel,
        originPostCategory,
      };
    }
  );
}

export async function openKanbanSelectionWizard(
  {
    actionDispatch,
    kanbanRepository,
    getGlobalState,
    getState,
  }: ActionContext<Store, WorkshopImplantationViewState>,
  implantation: WorkshopStandImplantation,
  standId: string,
  sortedCategories: readonly WorkshopPostCategory[],
  postId: string,
  users: readonly string[],
  qualifiedPostIdOrCategoryId: string,
  fromLoadingPost: boolean
): Promise<void> {
  const { kanbans } = getState();
  const { workflows } = getGlobalState().siteConfiguration;
  const allKanbans = await kanbanRepository.getAllEntities();
  const kanbansWithunfinishedOperationsForStandId = kanbanHelpers.getAllKanbansWithWorkOnStand(
    allKanbans,
    standId
  );
  const engageableKanbans: KanbanItem[] = [];
  const enagedKanbanIds = kanbans.map((k) => k.id);
  workflows.forEach((workflow) => {
    const precedingStandIds = workflowHelpers.findPrecedingNodesOf(workflow.definition, standId);
    const filteredStandIds = precedingStandIds ? [...precedingStandIds, standId] : [standId];
    const kanbanWithProgress = workflowProgressHelpers.filterKanbansByWorkflowAndComputeProgress(
      workflow.id,
      workflow.definition,
      kanbansWithunfinishedOperationsForStandId
    );
    kanbanWithProgress
      .filter(({ id }) => !enagedKanbanIds.includes(id))
      .filter((kanban) =>
        kanban.expectedStandIds.reduce<boolean>((p, c) => p || filteredStandIds.includes(c), false)
      )
      .forEach((kanban) => {
        const workloads: Record<CarViewCategory, number> = {
          MECA: 0,
          EXTE: 0,
          BUMP: 0,
          INTE: 0,
          MISC: 0,
        };
        packageDealHelpers.getAvailablePackageDeals(kanban.packageDeals).forEach((pd) => {
          packageDealHelpers
            .getUnfinishedOperationsInPackageDealForStandId(pd, standId)
            .forEach(({ workload }) => {
              workloads[carElementHelpers.getCarElementCategory(pd.carElement)] += workload;
            });
        });
        engageableKanbans.push({
          ...kanban,
          mecaWorkload: workloads.MECA,
          exteWorkload: workloads.EXTE + workloads.BUMP,
          inteWorkload: workloads.INTE,
          miscWorkload: workloads.MISC,
          totalWorkload:
            workloads.MECA + workloads.EXTE + workloads.BUMP + workloads.INTE + workloads.MISC,
        });
      });
  });
  let categoryId = '';
  if (!fromLoadingPost) {
    categoryId = nonnull(
      transverseHelpers.getAllPostInformationsFromQualifiedWorkshopPostId(
        qualifiedPostIdOrCategoryId
      ).categoryId
    );
  } else {
    categoryId = qualifiedPostIdOrCategoryId;
  }

  actionDispatch.scopeProperty('selectKanbanWizardState').reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (initial): SelectKanbanWizardState => {
      return {
        ...EMPTY_SELECT_KANBAN_WIZARD_STATE,
        isActive: true,
        selectableKanbans: engageableKanbans,
        standId,
        originPostForAction: postId,
        users,
        sortedCategories,
        originCategoryForAction: categoryId,
        fromLoadingPost,
        implantation,
      };
    }
  );
}

async function applyToggleOperationsForWorkshop<
  A extends AdditionalStepsForOperationCompletionModalState,
>(
  ctx: ActionContext<Store, A>,
  kanbanId: string,
  updatedPackageDeals: readonly DeepPartial<PackageDeal>[]
): Promise<void> {
  const { kanbanRepository } = ctx;
  await kanbanRepository.updateEntityFromPayload({
    entityId: kanbanId,
    payload: {
      packageDeals: updatedPackageDeals,
    },
  });
}

export async function toggleCompletedOperationStatusAction<
  A extends AdditionalStepsForOperationCompletionModalState,
>(
  { actionDispatch, kanbanRepository }: ActionContext<Store, A>,
  kanbanId: string,
  operationId: string,
  // Only used for Subcontractable operations once the SelectSubcontractorFirmModalDialog
  // has been shown to let the user select the right subcontractor
  subcontractableInfo?: SubcontractableInfo,
  // Only used for upload operations once the UploadDocumentForCompletionModalDialog
  // has been shown to let the user upload the required files
  selectedFiles?: readonly string[]
): Promise<void> {
  const kanban = await kanbanRepository.getEntity(kanbanId);

  await actionDispatch.exec(
    toggleOperationAction,
    kanbanId,
    kanban.packageDeals.filter(nonDeleted),
    operationId,
    applyToggleOperationsForWorkshop,
    subcontractableInfo,
    selectedFiles
  );
}

export function computeNewKanbansHandledInWorkshop(
  standId: string,
  implantationId: string,
  kanbansPreviouslyHandledInWorkshop: readonly Kanban[],
  updatedKanbans: readonly Kanban[]
): Kanban[] {
  // Find the kanbans that should be in the workshop implantation in the updated / added kanbans
  const newKanbansInWorkshop = kanbanHelpers.getKanbansCurrentlyInImplantation(
    updatedKanbans,
    nonnull(standId),
    nonnull(implantationId)
  );
  const newKanbansInWorkshopIds = newKanbansInWorkshop.map((k) => k.id);

  // Get the rest (i.e. the kanbans that should not be in the implantation)
  const newKanbansRest = updatedKanbans
    .filter((k) => !newKanbansInWorkshopIds.includes(k.id))
    .map((k) => k.id);

  // Remove the kanbans that were in the implantation but will not be with the new versions of the kanbans
  const kanbansWithRemovedNewOnes = kanbansPreviouslyHandledInWorkshop.filter(
    (k) => !newKanbansRest.includes(k.id)
  );
  const kanbansWithRemovedNewOnesMap = new Map<string, Kanban>();
  kanbansWithRemovedNewOnes.forEach((k) => {
    kanbansWithRemovedNewOnesMap.set(k.id, k);
  });

  // Update or add the kanbans in the state with their new / updated version
  newKanbansInWorkshop.forEach((k) => {
    kanbansWithRemovedNewOnesMap.set(k.id, k);
  });

  return [...kanbansWithRemovedNewOnesMap.values()];
}

export function updateKanbansHandledInWorkshopForDisplayViewStateFromSSEAction(
  { getState, actionDispatch }: ActionContext<Store, WorkshopImplantationViewState>,
  theKanbans: Kanban[]
): void {
  const { kanbans, implantationId, standId } = getState();

  const newKanbans = computeNewKanbansHandledInWorkshop(
    standId,
    implantationId,
    kanbans,
    theKanbans
  );
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      kanbans: newKanbans,
      kanbanInAnomalyIds: getIdsOfKanbansInAnomaly(nonnull(implantationId), newKanbans),
    };
  });
}
